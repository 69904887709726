import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'
import Cookie from 'js.cookie';
export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    if (!Cookie.get('alert') && $('#modal').length) {
      $('#modal').show();
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });

    $('img').parent('a').addClass('no-external-link');
    $('i').parent('a').addClass('no-external-link');

    /* serves hero image variant based on breakpoint
    *  needs cloudflare cdn on div with hero class
    */
    $(window).resize(function() {
      
      if ($(window).width() < 1600) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1920px', '/1600px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1600px', '/1920px');
         });
       }
       
      if ($(window).width() < 1440) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1600px', '/1440px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1440px', '/1600px');
         });
       }
       
      if ($(window).width() < 1200) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1440px', '/1200px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1200px', '/1440px');
         });
       }
       
      if ($(window).width() < 992) {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/1200px', '/992px');
         });
       } else {
         $('.hero.cloudflare').attr('style', function(_, value) {
           return value.replace('/992px', '/1200px');
         });
       }
       
       if ($(window).width() < 768) {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/992px', '/768px');
           });
         } else {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/768px', '/992px');
           });
         }
 
         if ($(window).width() < 576) {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/768px', '/576px');
           });
         } else {
           $('.hero.cloudflare').attr('style', function(_, value) {
             return value.replace('/576px', '/768px');
           });
         }
     }).trigger('resize');

     //sub-menu min-width equals parent's width
      $('li.menu-item-has-children .sub-menu').each(function() {
        $(this).css('min-width', $(this).parent().outerWidth());
      });

    //dropdown on click
    $('.banner nav li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
    });

    //close dropdown when clicking anywhere else
    $(document).on('click touch', function(e) {
      if( !$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length ) {
        $('.banner nav li.menu-item-has-children.open').removeClass('open');
      }
    });

    //sidenav dropdown on click
    $('.sideNav li.menu-item-has-children > a').click(function(e){
      e.preventDefault();
      $(this).next('.sideNav .sub-menu').slideToggle();
      $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
    });

    $('.sideNav .sub-menu a').click(function(){
      $('.hamburger').removeClass('is-active');
      $('#sideNav').removeClass('sideNav-open');
      $('.sideNavBody').removeClass('sideNavBody-push');
    });

    //lightgallery
    $('.lightgallery').each(function() {
      lightGallery(this, {
        licenseKey: 'F28B4CA0-283749F1-BBEB75B9-915E2B0B',
        selector: '.gallery-image',
        plugins: [lgZoom, lgThumbnail, fullScreen],
        speed: 500,
        fullScreen: true,
        subHtmlSelectorRelative: true,
        mobileSettings: {
          controls: true,
          showCloseIcon: true,
          download: false,
        },
      });
    });

    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: '.testimonials-panel .slider-nav',
        appendDots: '.testimonials-panel .slider-nav',
        adaptiveHeight: true,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

    //review us page
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
      localStorage.setItem('activeTab', $(e.target).attr('href'));
    });
    var activeTab = localStorage.getItem('activeTab');
    if (activeTab) {
      $('#review-tab a[href="' + activeTab + '"]').tab('show');
    }
  },
};
